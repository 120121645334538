import React from 'react';
import { useGetAllProjects } from '../hooks/projects';
import Nav from './custom/Nav';
import AddDayForm from './custom/forms/AddDayForm';

const AddDay = () => {
  const { data: projects, isLoading: projectsLoading } = useGetAllProjects({
    disabled: false,
  });

  return (
    <>
      <Nav title='Lisa uus' action='back' subAction={false} />
      <AddDayForm projects={projects} projectsLoading={projectsLoading} />
    </>
  );
};

export default AddDay;
