import { v4 as uuidv4 } from 'uuid';
import { useAlert } from '../components/custom/alertContext';

export const useSetAlert = () => {
  const { dispatch } = useAlert();
  const id = uuidv4();
  return {
    setAlert: (msg, alertType, timeout = 3000, customId = id) => {
      dispatch({
        type: 'SET_ALERT',
        payload: { msg, alertType, customId },
      });

      setTimeout(
        () =>
          dispatch({
            type: 'REMOVE_ALERT',
            payload: customId,
          }),
        timeout
      );
    },
  };
};
