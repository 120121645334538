import React from 'react';
import { useGetAllDays } from '../hooks/days';
// import { generatePDF } from '../hooks/pdf';
import { useQueryClient } from 'react-query';
// import { useSetAlert } from '../hooks/alert';
import { useDate } from './custom/selectedDateContext';

import Nav from './custom/Nav';
import BottomNav from './custom/BottomNav';
import Info from './custom/Info';
import Alert from './custom/Alert';
import Card from './custom/Card';

// import CircularProgress from '@material-ui/core/CircularProgress';

const Tunnileht = () => {
  // const { setAlert } = useSetAlert();
  const { state } = useDate();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData('me');
  // const [sendButton, setSendButton] = useState({
  //   disabled: false,
  //   title: 'Saada',
  // });
  // const [currentSelected, setCurrentselect] = useState({
  //   display: moment(Date.now()).format('MMMM YYYY'),
  //   date: moment(Date.now()).format('YYYY-MM'),
  // });

  const days = useGetAllDays({ user: user?.data.data._id, limit: 100 });

  // const handlePDF = async () => {
  //   setSendButton({
  //     disabled: true,
  //     title: <CircularProgress size={12} color='primary' />,
  //   });
  //   await generatePDF(state.date);
  //   setSendButton({
  //     disabled: false,
  //     title: 'Saada',
  //   });
  //   setAlert('Tunnileht saadetud!', 'success');
  // };

  return (
    <div className='App'>
      <Alert />
      <Nav
        title='Tunnileht'
        action='menu'
        subAction='send'
        currentSelected={state}
        user={user?.data}
      />
      <Info
        currentSelected={state}
        // setCurrentselect={setCurrentselect}
        days={days.data?.data.data}
        pdfShow={true}
      />

      <div className='content'>
        <Card
          days={days.data?.data.data}
          isLoading={days.isLoading}
          currentSelected={state}
        />
      </div>
      {/* <div className='action'>
        <button
          className='btn btn-send'
          disabled={sendButton.disabled}
          onClick={handlePDF}
        >
          {sendButton.title}
        </button>
      </div> */}
      <BottomNav tab={0} />
    </div>
  );
};

export default Tunnileht;
