import axios from 'axios';
// import { setAlert } from './alert';
import { useQuery } from 'react-query';

import { URL } from '../utils/config';

//GET PDFs

export const useGetPDFs = (userId) => {
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      user: userId,
      sort: '-month',
    },
  };
  // const body = JSON.stringify({ month: month });
  return useQuery(['pdfs', userId], () =>
    axios.get(`${URL}/api/v1/pdf`, config)
  );
};

// GENERATE PDF

export const generatePDF = (month) => {
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { month: month };
  const res = axios.post(`${URL}/api/v1/pdf`, body, config);

  return res;

  // dispatch(setAlert('Tunnileht saadetud!', 'success'));
};

//READ PDF

export const readPdf = async (fileName) => {
  const config = {
    withCredentials: true,
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  };
  const res = await axios.get(`${URL}/api/v1/pdf/${fileName}`, config);
  const file = new File([res.data], 'filename', { type: 'application/pdf' });
  // console.log(file);

  const fileURL = window.URL.createObjectURL(file);

  // let a = document.createElement('a');
  // document.body.appendChild(a);
  // a.style = 'display: none';
  // a.href = fileURL;
  // a.download = fileName;
  // a.click();
  // document.body.removeChild(a);

  // return (window.location = fileURL);
  // return window.location.assign(fileURL);
  // return fileURL;
  return window.open(fileURL, '_blank').focus();

  // window.open(fileURL, '_blank');
  // window.open(file, '_blank');
};
