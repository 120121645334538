import axios from 'axios';
// import { setAlert } from './alert';
import { URL } from '../utils/config';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// GET ALL NOTES

export const useGetNotes = (params) => {
  const config = {
    withCredentials: true,
    params: {
      ...params,
    },
  };

  return useQuery('notes', () => axios.get(`${URL}/api/v1/notes`, config));
};

// GET SINGLE NOTE

export const useGetNote = (id) => {
  const config = {
    withCredentials: true,
  };

  return useQuery(['notes', id], () => {
    return axios.get(`${URL}/api/v1/notes/${id}`, config);
  });
};

// CREATE NOTE

export const useCreateNote = () => {
  const queryClient = useQueryClient();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  // delete data['project'];
  // const body = JSON.stringify(data);

  return useMutation(
    async (data) => {
      console.log(data);
      return await axios.post(`${URL}/api/v1/notes`, data, config);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('notes', { exact: true });
      },
      onSettled: () => {
        queryClient.invalidateQueries('notes', { exact: true });
      },
    }
  );
};

// UPDATE NOTE

export const useUpdateNote = () => {
  const queryClient = useQueryClient();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const mutation = useMutation(
    async (values) =>
      await axios.put(
        `${URL}/api/v1/notes/${values.noteId}`,
        values.values,
        config
      ),
    {
      onMutate: async () => {
        await queryClient.cancelQueries('notes', { exact: true });
      },
      //   onSuccess: (note, variables) => {
      //     queryClient.setQueryData(['notes', variables?.noteId], note);
      //   },
      onSettled: () => {
        queryClient.invalidateQueries('notes', { exact: true });
      },
    }
  );

  return mutation;
};

// DELETE NOTE

export const useDeleteNote = () => {
  const queryClient = useQueryClient();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return useMutation(
    (values) => {
      return axios.delete(`${URL}/api/v1/notes/${values}`, config);
    },
    {
      onSettled: () => queryClient.invalidateQueries('notes', { exact: true }),
    }
  );
};
