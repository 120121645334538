import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteDialog = ({ actions, values }) => {
  const handleClickClose = () => {
    actions.setOpenDelete(false);
    if (actions.setUsertoDelete) {
      actions.setUserToDelete(null);
    }
  };

  return (
    <Dialog
      open={values.openDelete}
      onClose={handleClickClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {'Oled kindel, et soovid kustutada?'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClickClose} color='primary'>
          Ei
        </Button>
        <Button onClick={actions.deleteHandler} color='secondary'>
          Jah
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
