import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { useAlert } from './alertContext';

const Alerts = () => {
  const { state } = useAlert();
  function Alerts(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }
  return (
    <div className='alertsContainer'>
      {state !== null &&
        state.length > 0 &&
        state.map((alert) => (
          <Alerts key={alert.id} severity={alert.alertType} className='alert'>
            {alert.msg}
          </Alerts>
        ))}
    </div>
  );
};

export default Alerts;
