import { useDate } from '../components/custom/selectedDateContext';

// SET SELECTED DAY

export const useSetSelectedDay = () => {
  const { dispatch } = useDate();

  return {
    setSelectedDay: (selectedDate) => {
      dispatch({
        type: 'SET_DATE',
        payload: selectedDate,
      });
    },
  };
};
