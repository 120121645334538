import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const Card = ({ days, isLoading, currentSelected }) => {
  const history = useHistory();

  return !isLoading ? (
    <>
      {days
        .filter(
          (day) =>
            moment(day.date).format('YYYY-MM') ===
            moment(currentSelected.date).format('YYYY-MM')
        )
        .map((day, index) => (
          <div className='card' key={index}>
            <div className='card_first'>
              <div className='date'>
                <h3>{moment(day.date).format('DD')}</h3>
              </div>
            </div>
            <div className='card_second'>
              <div className='card_second-header'>
                <h4
                  className='card_second-title'
                  onClick={() =>
                    history.push(`${history.location.pathname}/${day._id}/edit`)
                  }
                >
                  {day.project.name}
                  <span className='pieceWork'>{`${
                    day.pieceWork ? '(TT)' : ''
                  }`}</span>
                </h4>
                <h6 className='card_second-time'>{`${day.hours}h`}</h6>
              </div>

              <span className='project-location'>
                {' '}
                {day.project.desc ? (
                  <span className='project-location'>{`${day.project.desc} | `}</span>
                ) : null}{' '}
                {`${moment(day.startTime).format('HH:mm')} - ${moment(
                  day.endTime
                ).format('HH:mm')}`}
              </span>

              <div className='card_second-desc'>
                <p>{day.desc}</p>
              </div>
            </div>
          </div>
        ))}
    </>
  ) : (
    <CircularProgress />
  );
};

export default Card;
