import React from 'react';
import Nav from '../custom/Nav';

const Dashboard = () => {
  return (
    <div className='App'>
      <Nav title='Töölaud' action='menu' subAction={false} />
    </div>
  );
};

export default Dashboard;
