import axios from 'axios';
// import { setAlert } from './alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { URL } from '../utils/config';

// GET ALL PROJECTS

export const useGetAllProjects = (params) => {
  const config = {
    withCredentials: true,
    params: {
      ...params,
    },
  };
  return useQuery('projects', () =>
    axios.get(`${URL}/api/v1/projects`, config)
  );
};

//GET SINGLE PROJECT

export const useGetOneProject = (projectId) => {
  const config = {
    withCredentials: true,
  };
  return useQuery(['projects', projectId], () =>
    axios.get(`${URL}/api/v1/projects/${projectId}`, config)
  );
};

// CREATE PROJECT

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return useMutation(
    async (values) => {
      return await axios.post(`${URL}/api/v1/projects`, values, config);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('projects', { exact: true });
      },
      onSettled: () => {
        queryClient.invalidateQueries('projects', { exact: true });
      },
    }
  );
};

//UPDATE PROJECT

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const mutation = useMutation(
    async (values) => {
      return await axios.put(
        `${URL}/api/v1/projects/${values.id}`,
        values.values,
        config
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('projects', { exact: true });
      },
      onSettled: () => {
        queryClient.invalidateQueries('projects', { exact: true });
      },
    }
  );

  return mutation;
};

//DELETE PROJECT

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const mutation = useMutation(
    async (id) => {
      return await axios.put(
        `${URL}/api/v1/projects/${id}`,
        { disabled: true },
        config
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('projects', { exact: true });
      },
      onSettled: () => {
        queryClient.invalidateQueries('projects', { exact: true });
      },
    }
  );

  return mutation;
};

// ADD BLUEPRINT TO PROJECT

export const useAddFilesToProject = () => {
  const queryClient = useQueryClient();
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return useMutation(
    async (values) => {
      console.log(values);
      const formData = new FormData();

      for (let x = 0; x < values.data.length; x++) {
        formData.append(`file`, values.data[x]);
        console.log(values.data[x]);
      }
      // formData.append('file', values.data);
      console.log(formData);
      return await axios.put(
        `${URL}/api/v1/projects/${values.projectId}/files`,
        formData,
        config
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('projects');
      },
      onSettled: (values) => {
        console.log(values);
        // queryClient.invalidateQueries(['projects', values.projectId]);
      },
    }
  );
};

//READ PDF
export const readProjectPdf = async (projectId, fileName) => {
  const config = {
    withCredentials: true,
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  };
  const res = await axios.get(
    `${URL}/api/v1/projects/${projectId}/${fileName}`,
    config
  );
  const fileURL = window.URL.createObjectURL(res.data);
  window.open(fileURL, '_blank');
};
