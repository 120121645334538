import React, { useState } from 'react';
import { useSetPassword, useAuth } from '../hooks/auth';
import { Redirect, useParams, useHistory } from 'react-router-dom';

// import Alert from '../components/Alert';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { InputAdornment } from '@material-ui/core';

const SetPassword = () => {
  const params = useParams();
  const history = useHistory();
  const { data, isLoading } = useAuth();
  const setPassword = useSetPassword();
  const [values, setValues] = useState({
    password: '',
  });

  const [showPW, setShowPW] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPW(!showPW);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setPassword.mutateAsync({
      data: values,
      passwordToken: params.passwordToken,
    });
    history.push('/tunnileht');
  };

  // Redirect if logged in
  if (data) {
    if (data?.data.data.role === 'admin') {
      return <Redirect to='/dashboard' />;
    } else {
      return <Redirect to='/tunnileht' />;
    }
  }

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='login'>
      <div className='nav'>
        <h2 className='nav-title'>Sisesta parool</h2>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='inputGroup'>
          <Input
            fullWidth
            placeholder='Parool'
            inputProps={{ 'aria-label': 'description' }}
            name='password'
            type={showPW ? 'text' : 'password'}
            onChange={(e) => handleChange(e)}
            value={values.password}
            startAdornment={
              <InputAdornment position='start'>
                <LockIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPW ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <button htmlFor='submit' className='btn btn-send'>
          Salvesta
        </button>
      </form>
      {/* <form onSubmit={(e) => handleSubmit(e)}>
        <div className='inputGroup'>
          <LockIcon fontSize='large' />
          <input
            type='password'
            name='password'
            onChange={(e) => handleChange(e)}
            value={values.password}
          />
        </div>
      </form> */}
    </div>
  );
};

export default SetPassword;
