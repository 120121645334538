import React, { useState } from 'react';
import Menu from '../Menu';
import hamburger from '../../icons/hamburger.svg';
// import add from '../../icons/add.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';

import { useSetAlert } from '../../hooks/alert';
import { generatePDF } from '../../hooks/pdf';

const Nav = ({ title, action, subAction, currentSelected, user }) => {
  const { setAlert } = useSetAlert();
  const history = useHistory();
  const [drawer, toggleDrawer] = useState(false);

  const [sendButton, setSendButton] = useState({
    disabled: false,
    title: <SendIcon />,
  });

  const handlePDF = async () => {
    setSendButton({
      disabled: true,
      title: <CircularProgress size={12} color='primary' />,
    });
    await generatePDF(currentSelected.date);
    setSendButton({
      disabled: false,
      title: <SendIcon />,
    });
    setAlert('Tunnileht saadetud!', 'success');
  };

  return (
    <>
      <div className='nav'>
        <Menu drawer={drawer} toggleDrawer={toggleDrawer} />
        {action === 'menu' ? (
          <img
            src={hamburger}
            alt='test'
            onClick={() => toggleDrawer(!drawer)}
          />
        ) : (
          <ArrowBackIcon onClick={() => history.goBack()} />
        )}
        <h2 className='nav-title'>{title}</h2>
        {subAction === 'send' ? (
          <IconButton
            className='btn-send'
            disabled={sendButton.disabled}
            onClick={handlePDF}
          >
            {sendButton.title}
          </IconButton>
        ) : // <img
        //   src={add}
        //   alt='test'
        //   onClick={() => history.push(`${history.location.pathname}/add`)}
        // />
        null}
      </div>
    </>
  );
};

export default Nav;
