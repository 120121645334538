import React, { useState } from 'react';
import Nav from '../custom/Nav';
import BottomNav from '../custom/BottomNav';
import DeleteDialog from '../custom/DeleteDialog';

import { useHistory } from 'react-router-dom';
import { useAllUsers, useDisableUser } from '../../hooks/users';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';

const Workers = () => {
  const { data } = useAllUsers({ disabled: false });
  const disableUser = useDisableUser();
  const users = data?.data;
  const history = useHistory();

  const [openDelete, setOpenDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleClickOpen = (id) => {
    setOpenDelete(true);
    setUserToDelete(id);
  };

  const deleteHandler = async () => {
    await disableUser.mutateAsync(userToDelete);
    setOpenDelete(false);
  };

  return users ? (
    <div className='app'>
      <DeleteDialog
        actions={{ setOpenDelete, deleteHandler, setUserToDelete }}
        values={{ openDelete }}
      />
      <Nav
        title={
          history.location.pathname === '/meeskond' ? 'Meeskond' : 'Tunnilehed'
        }
        action='menu'
        subAction={history.location.pathname === '/meeskond' ? true : false}
      />

      <div className='content'>
        {users?.data
          .filter((user) => user.role !== 'admin')
          .map((user, index) => (
            <div className='card' key={index}>
              <div className='card_first'>
                <div className='date'>
                  <Avatar
                    style={{ height: 48, width: 48 }}
                  >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
                </div>
              </div>
              <div className='card_second'>
                <div className='card_second-header'>
                  <h4
                    className='card_second-title'
                    // onClick={() =>
                    //   history.push(
                    //     `${history.location.pathname}/${user._id}/edit`
                    //   )
                    // }
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </h4>
                </div>
                <div className='card_second-desc'>
                  <p>{user.email}</p>
                </div>
              </div>
              {history.location.pathname === '/meeskond' && (
                <div className='card_third'>
                  <DeleteIcon
                    color='error'
                    onClick={() => handleClickOpen(user._id)}
                  />
                </div>
              )}
            </div>
          ))}
      </div>
      <BottomNav tab={0} />
    </div>
  ) : (
    <h1>Loading</h1>
  );
};

export default Workers;
