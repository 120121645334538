import React, { useState } from 'react';

import pdf from '../../icons/pdf.svg';
import Nav from '../custom/Nav';
import Select from '../custom/Select';
import { useAllUsers } from '../../hooks/users';
import { useQueryClient } from 'react-query';

// import { useParams } from 'react-router-dom';

import { useGetPDFs, readPdf } from '../../hooks/pdf';

const Tunnilehed = () => {
  const queryClient = useQueryClient();
  const me = queryClient.getQueryData('me');
  // const params = useParams();
  const { data } = useAllUsers({ disabled: false });
  const users = data?.data;

  const [userPdf, setUserPdf] = useState(me?.data.data._id);

  const getPdfs = useGetPDFs(userPdf);

  const handlePdf = (fileName) => {
    readPdf(fileName);
    // window.open(readPdf(fileName));
    // readPdf(fileName);
  };

  return (
    <div>
      <Nav title='Tunnilehed' action='menu' subAction={false} />
      <Select users={users} setUserPdf={setUserPdf} />

      {getPdfs.data?.data.data.map((pdfe, index) => (
        <div className='pdfs' key={index}>
          <img src={pdf} alt='pdf' />
          <h5 className='nonSelect' onClick={() => handlePdf(pdfe.fileName)}>
            {pdfe.fileName}
          </h5>
        </div>
      ))}
    </div>
  );
};

export default Tunnilehed;
