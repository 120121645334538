import React, { useState } from 'react';
import { useAddFilesToProject } from '../../hooks/projects';
import { useHistory, useParams } from 'react-router-dom';

import Nav from '../custom/Nav';
import Button from '@material-ui/core/Button';

const ProjectAddBlueprint = () => {
  const addFiles = useAddFilesToProject();
  const history = useHistory();
  const params = useParams();

  const [file, setFile] = useState({
    files: null,
  });

  const [disabledButton, setDisabledButton] = useState(false);

  const handleChange = (e) => {
    setFile({ files: e.target.files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    await addFiles.mutateAsync({
      projectId: params.projectId,
      data: file.files,
    });
    setDisabledButton(false);
    history.push(`/projektid/${params.projectId}`);
  };

  return (
    <div className='adddayform'>
      <Nav title='Lisa faile' action='back' subAction={false} />
      <form onSubmit={(e) => handleSubmit(e)} encType='multipart/form-data'>
        <input
          type='file'
          name='file'
          onChange={(e) => handleChange(e)}
          multiple
        />
        <Button
          color='secondary'
          variant='contained'
          fullWidth
          type='submit'
          disabled={disabledButton}
        >
          Lisa
        </Button>
      </form>
    </div>
  );
};

export default ProjectAddBlueprint;
