import axios from 'axios';
// import { setAlert } from './alert';
import { URL } from '../utils/config';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// GET ALL TOOLS

export const useGetTools = (params) => {
    const config = {
        withCredentials: true,
        params: {
            ...params,
        },
    };

    return useQuery('tools', () => axios.get(`${URL}/api/v1/tools`, config));
};

// GET SINGLE NOTE

export const useGetTool = (id) => {
    const config = {
        withCredentials: true,
    };

    return useQuery(['tools', id], () => {
        return axios.get(`${URL}/api/v1/tools/${id}`, config);
    });
};

// CREATE NOTE

export const useCreateTool = () => {
    const queryClient = useQueryClient();

    const config = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return useMutation(
        async (data) => {
            console.log(data);
            return await axios.post(`${URL}/api/v1/tools`, data, config);
        },
        {
            onMutate: async () => {
                await queryClient.cancelQueries('tools', { exact: true });
            },
            onSettled: () => {
                queryClient.invalidateQueries('tools', { exact: true });
            },
        }
    );
};

// UPDATE NOTE

export const useUpdateTool = () => {
    const queryClient = useQueryClient();

    const config = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const mutation = useMutation(
        async (values) =>
            await axios.put(
                `${URL}/api/v1/tools/${values.noteId}`,
                values.values,
                config
            ),
        {
            onMutate: async () => {
                await queryClient.cancelQueries('tools', { exact: true });
            },
            //   onSuccess: (note, variables) => {
            //     queryClient.setQueryData(['notes', variables?.noteId], note);
            //   },
            onSettled: () => {
                queryClient.invalidateQueries('tools', { exact: true });
            },
        }
    );

    return mutation;
};

// DELETE NOTE

export const useDeleteTool = () => {
    const queryClient = useQueryClient();

    const config = {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return useMutation(
        (values) => {
            return axios.delete(`${URL}/api/v1/tools/${values}`, config);
        },
        {
            onSettled: () => queryClient.invalidateQueries('tools', { exact: true }),
        }
    );
};
