import React, { createContext, useReducer, memo, useContext } from 'react';

const initialState = [];

const AlertContext = createContext();

const alertReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALERT': {
      return [...state, action.payload];
    }
    case 'REMOVE_ALERT': {
      return state.filter((alert) => alert.customId !== action.payload);
    }
    default: {
      return state;
    }
  }
};

export const AlertProvider = memo(({ children }) => {
  const [state, dispatch] = useReducer(alertReducer, initialState);
  const value = { state, dispatch };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
});

export const useAlert = () => {
  const alert = useContext(AlertContext);
  if (alert === 'undefined') {
    throw new Error('maggot oled');
  }
  return alert;
};
