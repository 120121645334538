import axios from 'axios';
// import { setAlert } from './alert';
import { URL } from '../utils/config';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// GET ALL DAYS

export const useGetAllDays = (params) => {
  const config = {
    withCredentials: true,
    params: {
      ...params,
    },
  };

  return useQuery('days', () => axios.get(`${URL}/api/v1/jobs`, config));
};

// GET SINGLE DAY

export const useGetDay = (id) => {
  const config = {
    withCredentials: true,
  };

  return useQuery(['days', id], () => {
    return axios.get(`${URL}/api/v1/jobs/${id}`, config);
  });
};

// CREATE DAY

export const useCreateDay = () => {
  const queryClient = useQueryClient();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  // delete data['project'];
  // const body = JSON.stringify(data);

  return useMutation(
    async (data) => {
      return await axios.post(
        `${URL}/api/v1/projects/${data.project}/jobs`,
        data.values,
        config
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('days', { exact: true });
      },
      onSettled: () => {
        queryClient.invalidateQueries('days', { exact: true });
      },
    }
  );
};

// UPDATE DAY

export const useUpdateDay = () => {
  const queryClient = useQueryClient();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const mutation = useMutation(
    async (values) =>
      await axios.put(
        `${URL}/api/v1/jobs/${values.jobId}`,
        values.values,
        config
      ),
    {
      onMutate: async () => {
        await queryClient.cancelQueries('days', { exact: true });
      },
      onSuccess: (day, variables) => {
        queryClient.setQueryData(['days', variables?.jobId], day);
      },
      onSettled: () => {
        queryClient.invalidateQueries('days', { exact: true });
      },
    }
  );

  return mutation;
};

// DELETE DAY

export const useDeleteDay = () => {
  const queryClient = useQueryClient();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return useMutation(
    (values) => {
      return axios.delete(`${URL}/api/v1/jobs/${values}`, config);
    },
    {
      onSettled: () => queryClient.invalidateQueries('days', { exact: true }),
    }
  );
};
