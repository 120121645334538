import React from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import pdf from '../../icons/pdf.svg';
import { useGetPDFs, readPdf } from '../../hooks/pdf';
import Select from './Select';

const Info = ({
  currentSelected,
  setCurrentselect,
  days,
  workers,
  users,
  pdfShow,
}) => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData('me');

  //   const days = useGetAllDays({ user: user?.data.data._id, limit: 100 });
  const getPdfs = useGetPDFs(user?.data.data._id);

  const openPdf = (fileName) => {
    readPdf(fileName);
  };

  const hours = () => {
    let total = 0;
    let pieceTotal = 0;
    let overtime = 0;
    if (days) {
      days
        .filter(
          (day) =>
            moment(day.date).format('YYYY-MM') ===
            moment(currentSelected.date).format('YYYY-MM')
        )
        // eslint-disable-next-line array-callback-return
        .map((day) => {
          if (!day.pieceWork) {
            total += day.hours;
          } else {
            pieceTotal += day.hours;
          }
          if (day.hours > 8.5) {
            overtime = overtime + (day.hours - 8.5);
          }
        });
    }
    return { total, pieceTotal, overtime };
  };

  return (
    <>
      <div className='info'>
        <div className='info-selector'>
          <Select
            currentSelectedDisplay={currentSelected.display}
            currentSelectedDate={currentSelected.date}
            setCurrentselect={setCurrentselect}
            days={days}
          />
          {workers ? <Select users={users?.data} /> : null}
        </div>
        <h6 className='info-hours'>
          Kokku:{' '}
          <span>{`${hours().total}h / TT: ${hours().pieceTotal}h `}</span>{' '}
          {user.firstName === 'Reimo' ? (
            <span>"/ ÜT: " {hours().overtime}</span>
          ) : null}
        </h6>
      </div>
      {!pdfShow ? null : (
        <div className='pdf'>
          {getPdfs.data?.data.data
            .filter(
              (pdf) =>
                pdf.month === moment(currentSelected.date).format('YYYY-MM')
            )
            .map((pdfe, index) => (
              <div className='pdfs' key={index}>
                <img src={pdf} alt='pdf' />
                <h5
                  className='nonSelect'
                  onClick={() => openPdf(pdfe.fileName)}
                >
                  {pdfe.fileName}
                </h5>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default Info;
