import axios from 'axios';
// import { setAlert } from './alert';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { URL } from '../utils/config';

// GET ALL USERS

export const useAllUsers = (params) => {
  const config = {
    withCredentials: true,
    params: {
      ...params,
    },
  };
  return useQuery('users', () => axios.get(`${URL}/api/v1/users`, config));
};

// GET SINGLE USER

export const useGetUser = (userId) => {
  const config = {
    withCredentials: true,
  };
  return useQuery(['user', userId], () =>
    axios.get(`${URL}/api/v1/users/${userId}`, config)
  );
};

//CREATE USER
export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const config = {
    withCredentials: true,
  };

  return useMutation(
    async (values) => {
      return await axios.post(`${URL}/api/v1/users`, values, config);
    },
    {
      onMutate: async () => {
        return await queryClient.cancelMutations('users');
      },
      onSettled: () => {
        return queryClient.invalidateQueries('users', { exact: true });
      },
    }
  );
};

//DISABLE USER
export const useDisableUser = () => {
  const queryClient = useQueryClient();
  const config = {
    withCredentials: true,
  };

  return useMutation(
    async (values) => {
      return await axios.put(
        `${URL}/api/v1/users/${values}/disable`,
        {},
        config
      );
    },
    {
      onMutate: async () => {
        return await queryClient.cancelMutations('users');
      },
      onSettled: () => {
        return queryClient.invalidateQueries('users', { exact: true });
      },
    }
  );
};
