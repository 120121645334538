import React from 'react';
import { useUpdateDay, useDeleteDay } from '../../../hooks/days';
import DeleteDialog from '../DeleteDialog';
import { useHistory, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const validationSchema = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  // password: yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),
});

const EditDayForm = ({
  day,
  projects,
  projectsAreLoading,
  openDelete,
  setOpenDelete,
}) => {
  const deleteDay = useDeleteDay();
  const updateDay = useUpdateDay();

  const history = useHistory();
  const params = useParams();

  const deleteHandler = async () => {
    await deleteDay.mutateAsync(params.jobId);
    history.push('/tunnileht');
  };

  const handleUpdate = (values, jobId) => {
    updateDay.mutateAsync({ values, jobId });
    history.push('/tunnileht');
  };

  const formik = useFormik({
    initialValues: {
      date: day?.data.data.date,
      startTime: day?.data.data.startTime,
      endTime: day?.data.data.endTime,
      project: day?.data.data.project._id,
      //   lunch: day?.data.lunch,
      //   pieceWork: day?.data.pieceWork,
      desc: day?.data.data.desc,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleUpdate(values, params.jobId);

      // updateDay(values, params.jobId);
      // history.push('/tunnileht');
    },
  });

  return (
    <div className='adddayform'>
      <DeleteDialog
        actions={{ setOpenDelete, deleteHandler }}
        values={{ openDelete }}
      />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={formik.handleSubmit}>
          <DatePicker
            fullWidth
            variant='inline'
            label='Kuupäev'
            value={formik.values.date}
            onChange={(date) => formik.setFieldValue('date', date, true)}
            autoOk
          />
          <TimePicker
            fullWidth
            variant='inline'
            ampm={false}
            minutesStep={15}
            label='Algus'
            value={formik.values.startTime}
            onChange={(date) => formik.setFieldValue('startTime', date, true)}
            autoOk
          />
          <TimePicker
            fullWidth
            variant='inline'
            ampm={false}
            minutesStep={15}
            label='Lõpp'
            value={formik.values.endTime}
            onChange={(date) => formik.setFieldValue('endTime', date, true)}
            autoOk
          />
          <div className='project_label'>
            <InputLabel variant='standard'>Objekt</InputLabel>
            <Select
              defaultValue={day.data?.data.project}
              fullWidth
              labelId='demo-simple-select-error-label'
              value={formik.values.project}
              id='demo-simple-select-error'
              onChange={(value) =>
                formik.setFieldValue('project', value.target.value, true)
              }
            >
              {!projectsAreLoading &&
                projects.data?.data.map((project, index) => (
                  <MenuItem key={index} value={project._id}>
                    {project.name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <TextField
            fullWidth
            id='desc'
            name='desc'
            label='Tehtud tööd'
            multiline
            value={formik.values.desc}
            onChange={formik.handleChange}
            // error={formik.touched.desc && Boolean(formik.errors.desc)}
            // helperText={formik.touched.desc && formik.errors.desc}
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={day.data.data.lunch}
                  checked={formik.values.lunch}
                  onChange={(value) =>
                    formik.setFieldValue('lunch', value.target.checked, false)
                  }
                  name='checkedB'
                  color='primary'
                />
              }
              label='Lõuna'
            />
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={day.data.data.pieceWork}
                  checked={formik.values.pieceWork}
                  onChange={(value) =>
                    formik.setFieldValue(
                      'pieceWork',
                      value.target.checked,
                      true
                    )
                  }
                  name='checkedB'
                  color='primary'
                />
              }
              label='Tükitöö'
            />
          </FormGroup>
          <Button color='primary' variant='contained' fullWidth type='submit'>
            Muuda
          </Button>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default EditDayForm;
