import axios from 'axios';
import { useSetAlert } from './alert';
import { URL } from '../utils/config';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// Load User
export const useAuth = () => {
  const config = {
    withCredentials: true,
  };
  return useQuery('me', () => axios.get(`${URL}/api/v1/auth/me`, config), {
    retry: false,
  });
};

export const useSetPassword = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useSetAlert();

  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return useMutation(
    (values) => {
      return axios.put(
        `${URL}/api/v1/auth/resetpassword/${values.passwordToken}`,
        values.data,
        config
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('me'),
      onError: (error) => setAlert(error.response?.data.error, 'error'),
    }
  );
};

// // Register User
// export const register = ({ name, email, password }) => async (dispatch) => {
//   const config = {
//     withCredentials: true,
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };

//   const body = JSON.stringify({ name, email, password });

//   try {
//     const res = await axios.post(`${URL}/api/v1/auth/register`, body, config);
//     dispatch({
//       type: REGISTER_SUCCESS,
//       payload: res.data,
//     });

//     dispatch(loadUser());
//   } catch (error) {
//     const errors = error.response.data.error;

//     if (errors) {
//       dispatch(setAlert(errors, 'error'));
//     }
//     dispatch({
//       type: REGISTER_FAIL,
//     });
//   }
// };

// Login User
export const useLogin = () => {
  const queryClient = useQueryClient();
  const { setAlert } = useSetAlert();
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return useMutation(
    (values) => {
      return axios.post(`${URL}/api/v1/auth/login`, values, config);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('me'),
      onError: (error) => setAlert(error.response?.data.error, 'error'),
    }
  );
};

// // Logout
// export const logout = () => (dispatch) => {
//   dispatch({ type: LOGOUT });
// };
