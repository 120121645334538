import React, { useState } from 'react';
import './select.css';
import moment from 'moment';
import 'moment/locale/et';
import { useSetSelectedDay } from '../../hooks/select';
import { useHistory } from 'react-router-dom';
// import { useDate } from './selectedDateContext';

const Select = ({
  // setCurrentselect,
  currentSelectedDisplay,
  // currentSelectedDate,
  days,
  users,
  setUserPdf,
}) => {
  const { setSelectedDay } = useSetSelectedDay();
  const history = useHistory();

  // console.log(history.location);
  const [select, setSelect] = useState(false);

  const options = () => {
    const results = [];
    if (days) {
      // eslint-disable-next-line array-callback-return
      days?.map((day) => {
        if (!results.includes(moment(day.date).format('YYYY-MM'))) {
          results.push(moment(day.date).format('YYYY-MM'));
        }
      });
    }
    if (users) {
      // eslint-disable-next-line array-callback-return
      users?.data
        .filter((user) => user.role !== 'admin')
        // eslint-disable-next-line array-callback-return
        .map((user) => {
          results.push({
            name: `${user.firstName} ${user.lastName}`,
            id: user._id,
          });
        });
    }
    return results;
  };

  const handleSelect = () => {
    setSelect(!select);
  };

  const handleOption = (e) => {
    setSelectedDay({
      display: moment(e.target.dataset.value).format('MMMM YYYY'),
      date: e.target.dataset.value,
    });
  };

  const handleOptionUsers = (e) => {
    if (history.location.pathname !== '/others') {
      setUserPdf(e.target.dataset.value);
    } else {
      console.log('Hooters!');
    }
  };

  return (
    <div className='custom-select-wrapper' onClick={handleSelect}>
      <div className={`custom-select ${select ? 'open' : ' '}`}>
        <div className='custom-select__trigger'>
          <span>{users ? 'Vali töötaja' : currentSelectedDisplay}</span>
          <div className='arrow'></div>
        </div>
        <div className='custom-options'>
          {days
            ? options().map((date, index) => (
                <span
                  key={index}
                  className='custom-option'
                  data-value={date}
                  onClick={(e) => handleOption(e)}
                >
                  {moment(date).format('MMMM YYYY')}
                </span>
              ))
            : options().map((user, index) => (
                <span
                  key={index}
                  className='custom-option'
                  data-value={user.id}
                  onClick={(e) => handleOptionUsers(e)}
                >
                  {user.name}
                </span>
              ))}
        </div>
      </div>
      <div className={`custom-select-overlay ${select ? 'open' : ' '}`}></div>
    </div>
  );
};

export default Select;
