import React from 'react';
import Nav from './custom/Nav';
import { useQueryClient } from 'react-query';

import {
    useGetTools,
    useCreateTool,
    // useUpdateTool,
    // useDeleteTool,
} from '../hooks/tools';


import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import {useHistory} from "react-router-dom";


const validationSchema = yup.object({
    // email: yup
    //   .string('Enter your email')
    //   .email('Enter a valid email')
    //   .required('Email is required'),
    // password: yup
    //   .string('Enter your password')
    //   .min(8, 'Password should be of minimum 8 characters length')
    //   .required('Password is required'),
});

const Tools = () => {
    const queryClient = useQueryClient();
    const user = queryClient.getQueryData('me');
    // const history = useHistory();

    const { data: tools, isLoading: toolsLoading } = useGetTools({
        user: user?.data.data._id,
    });
    const createTool = useCreateTool();
    // const updateTool = useUpdateTool();
    // const deleteTool = useDeleteTool();
    //
    // const getNotesSum = () => {
    //     let sum = 0;
    //     // eslint-disable-next-line array-callback-return
    //     notes?.data.data.map((note) => {
    //         if (note.done === false) {
    //             sum += note.amount;
    //         }
    //     });
    //     return sum;
    // };
    //
    // const handleDelete = () => {
    //     tools?.data.data.forEach((note) => {
    //         if (note.done === true) {
    //             deleteTool.mutateAsync(note._id);
    //         }
    //     });
    // };

    const formik = useFormik({
        initialValues: {
            name: '',
            productId: '',
            owner: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await createTool.mutateAsync(values);
            await formik.setValues({
                name: '',
                productId: '',
                owner: ''
            })
        },
    });

    return (
        <div>
            <Nav title='Tööriistad' action='menu' />

            <form onSubmit={formik.handleSubmit} className='notes_form'>
                <div className='inputGroup'>
                    <TextField
                        fullWidth
                        style={{ marginRight: 50 }}
                        id='name'
                        name='name'
                        label='Nimetus'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        fullWidth
                        style={{ marginRight: 50 }}
                        id='productId'
                        name='productId'
                        label='Tootekood'
                        value={formik.values.productId}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        fullWidth
                        style={{ marginRight: 50 }}
                        id='owner'
                        name='owner'
                        label='Omanik'
                        value={formik.values.owner}
                        onChange={formik.handleChange}
                    />
                    <div className='formAction'>
                        <Button
                            color='secondary'
                            variant='contained'
                            fullWidth
                            className='btn'
                            type='submit'
                            // onClick={() =>
                            //   setEditState({
                            //     id: project._id,
                            //     name: project.name,
                            //     editState: !editState.editState,
                            //   })
                            // }
                        >
                            Lisa tööriist
                        </Button>
                    </div>
                </div>
            </form>

            {!toolsLoading && (
                <div className='content'>
                    {tools?.data.data.map((tools, index) => (
                        <div className='card' key={index}>
                            <div className='card_second'>
                                <div className='card_second-header'>
                                    <h4
                                        className='card_second-title'
                                        // onClick={() =>
                                        //   history.push(`${history.location.pathname}/${notes._id}`)
                                        // }
                                        style={
                                            tools.done ? { textDecoration: 'line-through' } : null
                                        }
                                    >
                                        {tools.name}
                                    </h4>
                                </div>
                                <div
                                    className='card_second-time'
                                    style={tools.done ? { textDecoration: 'line-through' } : null}
                                >
                                    {tools.productId}
                                </div>
                            </div>
                            <div className='card_second-time'>
                                <span>{tools.owner}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}

        </div>
    );
};

export default Tools;
