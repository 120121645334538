import React from 'react';
import { useQueryClient } from 'react-query';

// import tunnileht from '../../icons/tunnileht.svg';
// import meeskond from '../../icons/meeskond.svg';
// import projekt from '../../icons/projekt.svg';
import { useHistory } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
});

const BottomNav = ({ tab }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData('me');
  const classes = useStyles();
  const [value, setValue] = React.useState(tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* <div className='nav'>
        <Menu drawer={drawer} toggleDrawer={toggleDrawer} />
        {action === 'menu' ? (
          <img
            src={hamburger}
            alt='test'
            onClick={() => toggleDrawer(!drawer)}
          />
        ) : (
          <ArrowBackIcon onClick={() => history.goBack()} />
        )}
        <h2 className='nav-title'>{title}</h2>
        {subAction ? (
          <img
            src={add}
            alt='test'
            onClick={() => history.push(`${history.location.pathname}/add`)}
          />
        ) : null}
      </div> */}
      <div className='bottomnav'>
        <Paper square className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'
            aria-label='icon tabs example'
          >
            <Tab
              icon={<AssignmentIndIcon fontSize='large' className='icon' />}
              aria-label='tunnileht'
              onClick={() =>
                history.push(
                  user.data.data.role !== 'admin' ? '/tunnileht' : '/tunnilehed'
                )
              }
            />
            <Fab
              aria-label='add'
              onClick={() => history.push(`${history.location.pathname}/add`)}
            >
              <AddIcon />
            </Fab>
            <Tab
              icon={<BusinessIcon fontSize='large' className='icon' />}
              aria-label='projekt'
              onClick={() => history.push('/projektid')}
            />
          </Tabs>
        </Paper>
      </div>
    </>
  );
};

export default BottomNav;
