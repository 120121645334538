import React, { useState } from 'react';
import {
  useGetAllProjects,
  useUpdateProject,
  useDeleteProject,
} from '../../hooks/projects';

import DeleteDialog from '../custom/DeleteDialog';
import Nav from '../custom/Nav';
import BottomNav from '../custom/BottomNav';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { useFormik } from 'formik';
import * as yup from 'yup';
// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { useHistory } from 'react-router-dom';

const validationSchema = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  // password: yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),
});

const Projects = () => {
  const { data: projects, isLoading: projectIsLoading } = useGetAllProjects({
    disabled: false,
  });

  const [openDelete, setOpenDelete] = useState(false);

  const history = useHistory();

  const updateProject = useUpdateProject();
  const deleteProject = useDeleteProject();

  // const handleSubmit = async (values) => {
  //   console.log('wtf');
  //   // await createProject.mutateAsync(values);
  //   history.push('/projektid');
  // };

  const formik = useFormik({
    initialValues: {
      name: '',
      desc: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await updateProject.mutateAsync({ id: editState.id, values });
      setEditState({ ...editState, editState: !editState.editState });
    },
  });

  const [editState, setEditState] = useState({
    id: '',
    editState: false,
    name: '',
    desc: '',
  });

  const handleEdit = (id, name, editToggle, desc) => {
    formik.initialValues.name = name;
    formik.initialValues.desc = desc;
    setEditState({ ...editState, editState: editToggle, id, name });
  };

  const deleteHandler = async () => {
    await deleteProject.mutateAsync(editState.id);
    history.push('/projektid');
  };

  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  return (
    <div>
      <Nav title='Objektid' action='menu' subAction={true} />
      {!projectIsLoading && (
        <div className='content'>
          {projects?.data.data.map((project, index) => (
            <div className='card' key={index}>
              <div className='card_second'>
                <div className='card_second-header'>
                  {editState.editState && editState.id === project._id ? (
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        fullWidth
                        id='name'
                        name='name'
                        // multiline
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        // error={formik.touched.desc && Boolean(formik.errors.desc)}
                        // helperText={formik.touched.desc && formik.errors.desc}
                      />
                      <TextField
                        fullWidth
                        id='desc'
                        name='desc'
                        label='Asukoht'
                        value={formik.values.desc}
                        onChange={formik.handleChange}
                        // error={formik.touched.desc && Boolean(formik.errors.desc)}
                        // helperText={formik.touched.desc && formik.errors.desc}
                      />
                      <div className='formAction'>
                        <IconButton
                          type='submit'
                          // onClick={() =>
                          //   setEditState({
                          //     id: project._id,
                          //     name: project.name,
                          //     editState: !editState.editState,
                          //   })
                          // }
                        >
                          <SaveIcon style={{ color: 'rgb(63, 194, 163)' }} />
                        </IconButton>
                        <IconButton onClick={handleClickOpen}>
                          <DeleteIcon style={{ color: '#ef2d56' }} />
                        </IconButton>
                        <DeleteDialog
                          actions={{ setOpenDelete, deleteHandler }}
                          values={{ openDelete }}
                        />
                      </div>
                    </form>
                  ) : (
                    <h4
                      className='card_second-title'
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}/${project._id}`
                        )
                      }
                    >
                      {project.name}
                    </h4>
                  )}
                </div>
              </div>
              {!editState.editState ? (
                <div className='card_second-time'>
                  <IconButton
                    onClick={() =>
                      handleEdit(
                        project._id,
                        project.name,
                        !editState.editState,
                        project.desc
                      )
                    }
                  >
                    <EditIcon style={{ color: 'rgb(63, 194, 163)' }} />
                  </IconButton>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      )}
      {/* {user.data.data.role !== 'admin' ? <BottomNav tab={2} /> : null} */}
      <BottomNav tab={2} />
    </div>
  );
};

export default Projects;
