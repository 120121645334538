import React, { createContext, useReducer, memo, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/et';

const initialState = {
  display: moment(Date.now()).format('MMMM YYYY'),
  date: moment(Date.now()).format('YYYY-MM'),
};
const DateContext = createContext();

const dateReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATE': {
      return { ...action.payload };
    }
    case 'GET_DATE': {
      //   return state.filter((alert) => alert.customId !== action.payload);
      return [...state];
    }
    default: {
      return state;
    }
  }
};

export const DateProvider = memo(({ children }) => {
  const [state, dispatch] = useReducer(dateReducer, initialState);
  const value = { state, dispatch };

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
});

export const useDate = () => {
  const date = useContext(DateContext);
  if (date === 'undefined') {
    throw new Error('maggot oled');
  }
  return date;
};
