import React from 'react';
import { useCreateProject } from '../../hooks/projects';
import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Nav from '../custom/Nav';

const validationSchema = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  // password: yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),
});

const ProjectAddForm = () => {
  const createProject = useCreateProject();
  const history = useHistory();

  const handleSubmit = async (values) => {
    await createProject.mutateAsync(values);
    history.push('/projektid');
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      desc: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className='adddayform'>
      <Nav title='Lisa objekt' action='back' subAction={false} />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Objekti nimi'
          multiline
          value={formik.values.name}
          onChange={formik.handleChange}
          // error={formik.touched.desc && Boolean(formik.errors.desc)}
          // helperText={formik.touched.desc && formik.errors.desc}
        />
        <TextField
          fullWidth
          id='desc'
          name='desc'
          label='Asukoht'
          value={formik.values.desc}
          onChange={formik.handleChange}
          // error={formik.touched.desc && Boolean(formik.errors.desc)}
          // helperText={formik.touched.desc && formik.errors.desc}
        />
        <Button color='secondary' variant='contained' fullWidth type='submit'>
          Lisa
        </Button>
      </form>
    </div>
  );
};

export default ProjectAddForm;
