import React from 'react';
import { useAuth } from '../hooks/auth';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import menu_arrow from '../icons/menu_arrow.svg';
import tunnileht from '../icons/tunnileht.svg';
import meeskond from '../icons/meeskond.svg';
import projekt from '../icons/projekt.svg';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AccessibleIcon from '@material-ui/icons/Accessible';
import BuildIcon from '@material-ui/icons/Build';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  avatarSize: {
    height: 48,
    width: 48,
  },
});

const Menu = ({ drawer, toggleDrawer }) => {
  const { data } = useAuth();

  const AdminMenu = () => {
    return (
      <>
        <Link to='/meeskond'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <img src={meeskond} alt='meeskond' />
            </div>
            <div className='menu_items--2'>
              <h6>Meeskond</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
        </Link>
        <Divider />
        <Link to='/projektid'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <img src={projekt} alt='projekt' />
            </div>
            <div className='menu_items--2'>
              <h6>Objektid</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
        </Link>
        <Divider />
        <Link to='/tunnilehed'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <img src={tunnileht} alt='tunnileht' />
            </div>
            <div className='menu_items--2'>
              <h6>Tunnilehed</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
        </Link>
        <Divider />
<Link to='/others'>
              <div className='menu_items'>
                <div className='menu_items--1'>
                  <AccessibleIcon />
                </div>
                <div className='menu_items--2'>
                  <h6>Piilur</h6>
                </div>
                <div className='menu_items--3'>
                  <img src={menu_arrow} alt='menu_arrow' />
                </div>
              </div>
            </Link>
            <Divider />

        <Link to='/tools'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <BuildIcon />
            </div>
            <div className='menu_items--2'>
              <h6>Tööriistad</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
        </Link>
        <Divider />

      </>
    );
  };
  const UserMenu = () => {
    return (
      <>
        <Link to='/tunnileht'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <AssignmentIndIcon />
              {/* <img src={tunnileht} alt='tunnileht' /> */}
            </div>
            <div className='menu_items--2'>
              <h6>Tunnileht</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
        </Link>
        <Divider />
        <Link to='/projektid'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <BusinessIcon />
            </div>
            <div className='menu_items--2'>
              <h6>Objektid</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
        </Link>
        <Divider />
        <Link to='/m2rkmed'>
          <div className='menu_items'>
            <div className='menu_items--1'>
              <ReceiptIcon />
            </div>
            <div className='menu_items--2'>
              <h6>Märkmed</h6>
            </div>
            <div className='menu_items--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
          <Divider />
        </Link>

        {data.data?.data.role === 'karl' ? (
          <>
            <Link to='/others'>
              <div className='menu_items'>
                <div className='menu_items--1'>
                  <AccessibleIcon />
                </div>
                <div className='menu_items--2'>
                  <h6>Piilur</h6>
                </div>
                <div className='menu_items--3'>
                  <img src={menu_arrow} alt='menu_arrow' />
                </div>
              </div>
            </Link>
            <Divider />
            <Link to='/tools'>
              <div className='menu_items'>
                <div className='menu_items--1'>
                  <BuildIcon />
                </div>
                <div className='menu_items--2'>
                  <h6>Tööriistad</h6>
                </div>
                <div className='menu_items--3'>
                  <img src={menu_arrow} alt='menu_arrow' />
                </div>
              </div>
            </Link>
            <Divider />
          </>
        ) : null}
      </>
    );
  };

  const classes = useStyles();
  return (
    <Drawer anchor='left' open={drawer} onClose={() => toggleDrawer(false)}>
      <div className='menu'>
        <h3 className='company_name'>Nordmus</h3>
        <div className='menu_content'>
          <div className='profile'>
            <div className='profile--1'>
              <Avatar
                className={classes.avatarSize}
              >{`${data?.data.data.firstName[0]}${data?.data.data.lastName[0]}`}</Avatar>
            </div>
            <div className='profile--2'>
              <h6>{`${data?.data.data.firstName} ${data?.data.data.lastName}`}</h6>
              <span>Nordmus OÜ</span>
            </div>
            <div className='profile--3'>
              <img src={menu_arrow} alt='menu_arrow' />
            </div>
          </div>
          <Divider />
          {data?.data.data.role === 'admin' ? AdminMenu() : UserMenu()}
          {/* <Link to='/tunnileht'>
            <div className='menu_items'>
              <div className='menu_items--1'>
                <img src={tunnileht} alt='tunnileht' />
              </div>
              <div className='menu_items--2'>
                <h6>Tunnileht</h6>
              </div>
              <div className='menu_items--3'>
                <img src={menu_arrow} alt='menu_arrow' />
              </div>
            </div>
          </Link>
          <Divider /> */}
        </div>
      </div>
    </Drawer>
  );
};

export default Menu;
