import './css/main.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useAuth } from './hooks/auth';
import { AlertProvider } from './components/custom/alertContext';
import { DateProvider } from './components/custom/selectedDateContext';

//components
import Tunnileht from './components/Tunnileht';
import Login from './components/Login';
import AddDay from './components/AddDay';
import EditDay from './components/EditDay';
import SetPassword from './components/SetPassword';
import Notes from './components/Notes';
import Tools from './components/Tools';

//admin components
import Dashboard from './components/Admin/Dashboard';
import Workers from './components/Admin/Workers';
import WorkersAddForm from './components/Admin/WorkersAddForm';
import Tunnilehed from './components/Admin/Tunnilehed';
import Projects from './components/Admin/Projects';
import Project from './components/Admin/Project';
import ProjectAddForm from './components/Admin/ProjectAddForm';
import ProjectAddFiles from './components/Admin/ProjectAddFiles';
import LiveJobs from './components/custom/LiveJobs';
import LiveJobsContent from './components/custom/LiveJobsContent';

// routes
import PrivateRoute from './components/router/PrivateRoute';

function App() {
  const { data: user } = useAuth();

  return user ? (
    user.data?.data.role === 'user' || user.data?.data.role === 'karl' ? (
      //USER ROUTING
      <AlertProvider>
        <DateProvider>
          <Router>
            {/* <Route exact path='/' component={Login} />
      <Route exact path='/login' component={Login} /> */}
            {/* <Route exact path='/'>
          {data?.data.data.role === 'admin' ? (
            <Redirect to='/dashboard' />
          ) : (
            <Redirect to='tunnileht' />
          )}
        </Route> */}
            <Route path='/'>
              <Redirect to='/tunnileht' />
            </Route>
            <Switch>
              <PrivateRoute
                exact
                path='/tunnileht'
                component={Tunnileht}
                data={user}
              />
              <PrivateRoute
                exact
                path='/tunnileht/add'
                component={AddDay}
                data={user}
              />
              <PrivateRoute
                exact
                path='/tunnileht/:jobId/edit'
                component={EditDay}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid'
                component={Projects}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid/add'
                component={ProjectAddForm}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid/:projectId'
                component={Project}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid/:projectId/add'
                component={ProjectAddFiles}
                data={user}
              />
              <PrivateRoute
                exact
                path='/m2rkmed'
                component={Notes}
                data={user}
              />
              {/* Hiljem muuta!!!!! */}
              <PrivateRoute
                exact
                path='/others'
                component={LiveJobs}
                data={user}
              />
              <PrivateRoute
                  exact
                  path='/tools'
                  component={Tools}
                  data={user}
              />
              <PrivateRoute
                exact
                path='/others/:userId/jobs'
                component={LiveJobsContent}
                data={user}
              />
            </Switch>
          </Router>
        </DateProvider>
      </AlertProvider>
    ) : (
      // ADMIN ROUTING
      <AlertProvider>
        <DateProvider>
          <Router>
            <Route path='/'>
              <Redirect to='/tunnilehed' />
            </Route>
            <Switch>
              <PrivateRoute
                exact
                path='/dashboard'
                component={Dashboard}
                data={user}
              />
              <PrivateRoute
                exact
                path='/meeskond'
                component={Workers}
                data={user}
              />
              <PrivateRoute
                exact
                path='/meeskond/add'
                component={WorkersAddForm}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid'
                component={Projects}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid/add'
                component={ProjectAddForm}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid/:projectId'
                component={Project}
                data={user}
              />
              <PrivateRoute
                exact
                path='/projektid/:projectId/add'
                component={ProjectAddFiles}
                data={user}
              />
              <PrivateRoute
                exact
                path='/tunnilehed'
                component={Tunnilehed}
                data={user}
              />
              <PrivateRoute
                exact
                path='/others'
                component={LiveJobs}
                data={user}
              />
<PrivateRoute
                exact
                path='/others/:userId/jobs'
                component={LiveJobsContent}
                data={user}
              />
              {/* <PrivateRoute
                exact
                path='/tunnilehed/:userId'
                component={Tunnilehed}
                data={user}
              /> */}
            </Switch>
          </Router>
        </DateProvider>
      </AlertProvider>
    )
  ) : (
    <AlertProvider>
      <Router>
        <Route
          exact
          path='/setPassword/:passwordToken'
          component={SetPassword}
        />
        <Route path='/' component={Login} />
        <Route exact path='/login' component={Login} />
      </Router>
    </AlertProvider>
  );
}

export default App;
