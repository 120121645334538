import React, { useState } from 'react';
import { useLogin, useAuth } from '../hooks/auth';
import { Redirect } from 'react-router-dom';
import Alert from '../components/custom/Alert';

// import AccountCircle from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Input from '@material-ui/core/Input';
import { InputAdornment } from '@material-ui/core';

const Login = () => {
  const { data, isLoading } = useAuth();
  const login = useLogin();
  const [values, setValues] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login.mutateAsync({
      email: values.username.toLowerCase(),
      password: values.password,
    });
  };

  // Redirect if logged in
  if (data) {
    if (data?.data.data.role === 'admin') {
      return <Redirect to='/dashboard' />;
    } else {
      return <Redirect to='/tunnileht' />;
    }
  }

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className='login'>
      <Alert />
      <div className='iconTitle'>
        <h2>Logi sisse</h2>
        {/* <AccountCircle fontSize='inherit' /> */}
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='inputGroup'>
          <Input
            fullWidth
            placeholder='kasutaja@email.ee'
            inputProps={{ 'aria-label': 'description' }}
            name='username'
            type='email'
            onChange={(e) => handleChange(e)}
            value={values.username}
            startAdornment={
              <InputAdornment position='start'>
                <PersonIcon />
              </InputAdornment>
            }
          />
          {/* <AccountCircle fontSize='large' />
          <input
            type='email'
            name='username'
            onChange={(e) => handleChange(e)}
            value={values.username}
          /> */}
        </div>
        <div className='inputGroup'>
          <Input
            fullWidth
            placeholder='parool'
            inputProps={{ 'aria-label': 'description' }}
            name='password'
            type='password'
            onChange={(e) => handleChange(e)}
            value={values.password}
            startAdornment={
              <InputAdornment position='start'>
                <LockIcon />
              </InputAdornment>
            }
          />
          {/* <LockIcon fontSize='large' />
          <input
            type='password'
            name='password'
            onChange={(e) => handleChange(e)}
            value={values.password}
          /> */}
        </div>
        <button htmlFor='submit' className='btn btn-send'>
          Sisene
        </button>
      </form>
    </div>
  );
};

export default Login;
