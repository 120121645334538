import React from 'react';
import Nav from './custom/Nav';
import { useQueryClient } from 'react-query';

import {
  useGetNotes,
  useCreateNote,
  useUpdateNote,
  useDeleteNote,
} from '../hooks/notes';


import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';


const validationSchema = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  // password: yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),
});

const Notes = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData('me');
  // const history = useHistory();

  const { data: notes, isLoading: notesLoading } = useGetNotes({
    user: user?.data.data._id,
  });
  const createNote = useCreateNote();
  const updateNote = useUpdateNote();
  const deleteNote = useDeleteNote();

  const getNotesSum = () => {
    let sum = 0;
    // eslint-disable-next-line array-callback-return
    notes?.data.data.map((note) => {
      if (note.done === false) {
        sum += note.amount;
      }
    });
    return sum;
  };

  const handleDelete = () => {
    notes?.data.data.forEach((note) => {
      if (note.done === true) {
        deleteNote.mutateAsync(note._id);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      amount: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await createNote.mutateAsync(values);
    },
  });

  return (
    <div>
      <Nav title='Märkmed' action='menu' />
      <div className='info'>
        <div className='info_selector'>
          <Button variant='outlined' color='secondary' onClick={handleDelete}>
            Kustuta märgitud
          </Button>
        </div>
        <h6 className='info-hours'>
          Kokku: <span>{`${getNotesSum()}€`}</span>
        </h6>
      </div>
      <form onSubmit={formik.handleSubmit} className='notes_form'>
        <div className='inputGroup'>
          <TextField
            // fullWidth
            style={{ marginRight: 50 }}
            id='name'
            name='name'
            label='Uus märge'
            value={formik.values.name}
            onChange={formik.handleChange}
            // error={formik.touched.desc && Boolean(formik.errors.desc)}
            // helperText={formik.touched.desc && formik.errors.desc}
          />
          <TextField
            // fullWidth
            style={{ marginRight: 50 }}
            id='amount'
            name='amount'
            label='Summa'
            value={formik.values.amount}
            onChange={formik.handleChange}
            type='number'
            onInvalid={(e) => {
              e.target.validity.badInput
                ? e.target.setCustomValidity('Sisesta number!')
                : e.target.setCustomValidity('');
            }}
            // error={formik.touched.number && Boolean(formik.errors.number)}
            // helperText={formik.touched.desc && formik.errors.desc}
          />
          <div className='formAction'>
            <Button
              color='secondary'
              variant='contained'
              fullWidth
              className='btn'
              type='submit'
              // onClick={() =>
              //   setEditState({
              //     id: project._id,
              //     name: project.name,
              //     editState: !editState.editState,
              //   })
              // }
            >
              Lisa märge
            </Button>
          </div>
        </div>
      </form>
      {!notesLoading && (
        <div className='content'>
          {notes?.data.data.map((notes, index) => (
            <div className='card' key={index}>
              <div className='card_second'>
                <div className='card_second-header'>
                  <h4
                    className='card_second-title'
                    // onClick={() =>
                    //   history.push(`${history.location.pathname}/${notes._id}`)
                    // }
                    style={
                      notes.done ? { textDecoration: 'line-through' } : null
                    }
                  >
                    {notes.name}
                  </h4>
                </div>
                <div
                  className='card_second-time'
                  style={notes.done ? { textDecoration: 'line-through' } : null}
                >
                  {notes.amount}€
                </div>
              </div>
              <div className='card_second-time'>
                <Switch
                  defaultChecked={notes.done}
                  checked={notes.done}
                  onChange={() =>
                    updateNote.mutateAsync({
                      noteId: notes._id,
                      values: { done: true },
                    })
                  }
                  name='checkedB'
                  color='primary'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {/* <img
        src='https://hospital.dypatilmedicalkop.org/wp-content/uploads/2020/07/page-constrction.jpg'
        alt='construction'
      /> */}
      {/* <BottomNav /> */}
    </div>
  );
};

export default Notes;
