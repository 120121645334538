import React from 'react';
import Nav from '../custom/Nav';
import BottomNav from '../custom/BottomNav';
import pdf from '../../icons/pdf.svg';

import { useGetOneProject, readProjectPdf } from '../../hooks/projects';
import { useParams } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const Project = () => {
  const params = useParams();
  const { data: project, isLoading: projectIsLoading } = useGetOneProject(
    params.projectId
  );

  const handleOpenFile = (fileName) => {
    readProjectPdf(params.projectId, fileName);
  };
  return (
    !projectIsLoading && (
      <div>
        <Nav title={project.data?.data.name} action='back' subAction={true} />

        {!projectIsLoading && (
          <div className='content'>
            <h2 className='project--header'>Joonised</h2>
            <div className='project_files'>
              {project.data?.data.files.map((file, index) => (
                <div className='pdfs' key={index}>
                  <img src={pdf} alt='pdf' />
                  <h5
                    className='nonSelect'
                    onClick={() => handleOpenFile(file)}
                  >
                    {file}
                  </h5>
                </div>
              ))}
            </div>
            <Divider />
            <h2 className='project--header'>Pildid</h2>
            <div className='project_photos'>
              <GridList cellHeight={160} cols={3}>
                {project.data?.data.photos
                  .map((photo, index) => (
                    <GridListTile key={index}>
                      <img
                        src={photo.fileName}
                        alt='gibberish'
                        onClick={() => window.open(photo.fileName, '_blank')}
                      />
                    </GridListTile>
                  ))
                  .reverse()}
              </GridList>
              {/* <h5>Pilte pole</h5> */}
            </div>
          </div>
        )}
        {/* {user.data.data.role !== 'admin' ? <BottomNav tab={2} /> : null} */}
        <BottomNav tab={2} />
      </div>
    )
  );
};

export default Project;
