import React, { useState } from 'react';
import EditDayForm from './custom/forms/EditDayForm';
import { useGetAllProjects } from '../hooks/projects';
import { useGetDay } from '../hooks/days';
import { useHistory, useParams } from 'react-router-dom';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

const EditDay = () => {
  const history = useHistory();
  const params = useParams();

  const [openDelete, setOpenDelete] = useState(false);

  const { data: day, isLoading: dayIsLoading } = useGetDay(params.jobId);
  const { data: projects, isLoading: projectIsLoading } = useGetAllProjects({
    disabled: false,
  });

  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  return (
    <div>
      <div className='nav'>
        <ArrowBackIcon onClick={() => history.goBack()} />
        <h2 className='nav-title'>Muuda</h2>
        <DeleteForeverIcon color={'secondary'} onClick={handleClickOpen} />
      </div>
      {!dayIsLoading ? (
        <EditDayForm
          day={day}
          projects={projects}
          dayIsloading={dayIsLoading}
          projectsAreLoading={projectIsLoading}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default EditDay;
