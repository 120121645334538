import React from 'react';
import Nav from './Nav';
import { useParams } from 'react-router-dom';
import { useDate } from './selectedDateContext';
import { useGetAllDays } from '../../hooks/days';
import { useGetUser } from '../../hooks/users';

import Card from './Card';
import Info from './Info';

const LiveJobsContent = () => {
  const params = useParams();
  const { state } = useDate();

  const days = useGetAllDays({ user: params.userId, limit: 100 });
  const user = useGetUser(params.userId);

  return (
    <div className='App'>
      <Nav
        title={`${user?.data?.data.data.firstName} ${user?.data?.data.data.lastName}`}
        action='back'
      />
      <Info
        currentSelected={state}
        // setCurrentselect={setCurrentselect}
        days={days.data?.data.data}
        pdfShow={false}
      />
      <Card
        days={days.data?.data.data}
        isLoading={days.isLoading}
        currentSelected={state}
      />
    </div>
  );
};

export default LiveJobsContent;
