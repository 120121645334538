import React from 'react';
import { useCreateDay } from '../../../hooks/days';
// import { useSetAlert } from '../../../hooks/alert';
import { useHistory } from 'react-router-dom';
import Alert from '../Alert';
import { useSetSelectedDay } from '../../../hooks/select';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';

const validationSchema = yup.object({
  project: yup.string('Vali Objekt!').required('Objekt on vajalik!'),
  desc: yup.string('Kirjeldus').required('Kirjeldus on vajalik!'),
});

const AddDayForm = ({ projects, projectsLoading }) => {
  const { setSelectedDay } = useSetSelectedDay();

  // const { setAlert } = useSetAlert();
  const createDay = useCreateDay();
  const history = useHistory();

  const handleSubmit = async (values) => {
    await createDay.mutateAsync(values);
    history.push('/tunnileht');
  };

  const formik = useFormik({
    initialValues: {
      date: moment(Date.now()).format(),
      startTime: moment('08:00', 'HH:mm').format(),
      endTime: moment('17:00', 'HH:mm').format(),
      lunch: true,
      pieceWork: false,
      desc: '',
      project: '',
    },
    // validateOnBlur: false,
    // validateOnMount: false,
    // validateOnChange: false,
    // isInitialValid: true,
    validationSchema: validationSchema,
    // validate: () => {
    //   for (const error in formik.errors) {
    //     setAlert(formik.errors[error], 'error', 3000, formik.errors[error]);
    //   }
    // },
    onSubmit: (values) => {
      setSelectedDay({
        display: moment(values.date).format('MMMM YYYY'),
        date: moment(values.date).format('YYYY-MM'),
      });
      handleSubmit({ values, project: values.project });
    },
  });

  // console.log(formik.errors);
  return (
    <div className='adddayform'>
      <Alert />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <form onSubmit={formik.handleSubmit}>
          <DatePicker
            fullWidth
            variant='inline'
            label='Kuupäev'
            value={formik.values.date}
            onChange={(date) => formik.setFieldValue('date', date, true)}
            autoOk
            // onChange={(date) => formik.handleChange(date)}
          />
          <TimePicker
            fullWidth
            variant='inline'
            ampm={false}
            label='Algus'
            minutesStep={15}
            value={formik.values.startTime}
            onChange={(date) => formik.setFieldValue('startTime', date, true)}
            autoOk
          />
          <TimePicker
            fullWidth
            variant='inline'
            ampm={false}
            label='Lõpp'
            minutesStep={15}
            value={formik.values.endTime}
            onChange={(date) => formik.setFieldValue('endTime', date, true)}
            autoOk
          />
          <div className='project_label'>
            <InputLabel variant='standard'>Objekt</InputLabel>
            <Select
              fullWidth
              labelId='demo-simple-select-error-label'
              value={formik.values.project}
              id='demo-simple-select-error'
              onChange={(value) =>
                formik.setFieldValue('project', value.target.value, true)
              }
            >
              {!projectsLoading &&
                projects.data.data.map((project, index) => (
                  <MenuItem key={index} value={project._id}>
                    {project.name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <TextField
            fullWidth
            id='desc'
            name='desc'
            label='Tehtud tööd'
            multiline
            value={formik.values.desc}
            onChange={formik.handleChange}
            // error={formik.touched.desc && Boolean(formik.errors.desc)}
            // helperText={formik.touched.desc && formik.errors.desc}
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.lunch}
                  onChange={(value) =>
                    formik.setFieldValue('lunch', value.target.checked, false)
                  }
                  name='checkedB'
                  color='secondary'
                />
              }
              label='Lõuna'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.pieceWork}
                  onChange={(value) =>
                    formik.setFieldValue(
                      'pieceWork',
                      value.target.checked,
                      false
                    )
                  }
                  name='checkedB'
                  color='secondary'
                />
              }
              label='Tükitöö'
            />
          </FormGroup>
          <Button color='secondary' variant='contained' fullWidth type='submit'>
            Lisa
          </Button>
        </form>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default AddDayForm;
