import React from 'react';
// import { useGetAllDays } from '../../hooks/days';
import { useQueryClient } from 'react-query';
import { useDate } from './selectedDateContext';
import { useAllUsers } from '../../hooks/users';
import { useHistory } from 'react-router-dom';

import Nav from './Nav';
// import BottomNav from './BottomNav';
// import Info from './Info';
import Alert from './Alert';
// import Card from './Card';

import Avatar from '@material-ui/core/Avatar';

const LiveJobs = () => {
  const { state } = useDate();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData('me');
  const history = useHistory();

  // const days = useGetAllDays({ limit: 150 });
  const users = useAllUsers({ disabled: false });

  // console.log(user);

  return (
    <div className='App'>
      <Alert />
      <Nav
        title='Piilur'
        action='menu'
        currentSelected={state}
        user={user?.data}
      />
      {/* <Info
        currentSelected={state}
        // setCurrentselect={setCurrentselect}
        days={days.data?.data.data}
        workers={true}
        users={users?.data}
      /> */}

      <div className='content'>
        {users.data?.data.data
          .filter(
            (user2) =>
              user2._id !== user?.data?.data._id && user2.role !== 'admin'
          )
          .map((user1, index) => (
            <div className='card' key={index}>
              <div className='card_first'>
                <div className='date'>
                  <Avatar
                    style={{ height: 48, width: 48 }}
                  >{`${user1?.firstName[0]}${user1?.lastName[0]}`}</Avatar>
                </div>
              </div>
              <div className='card_second'>
                <div className='card_second-header'>
                  <h4
                    className='card_second-title'
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}/${user1._id}/jobs`
                      )
                    }
                  >
                    {`${user1.firstName} ${user1.lastName}`}
                  </h4>
                </div>
                <div className='card_second-desc'>
                  <p>{user1.email}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* <Card
          days={days.data?.data.data}
          isLoading={days.isLoading}
          currentSelected={state}
        /> */}
      {/* <div className='action'>
      <button
        className='btn btn-send'
        disabled={sendButton.disabled}
        onClick={handlePDF}
      >
        {sendButton.title}
      </button>
    </div> */}
      {/* <BottomNav tab={0} /> */}
    </div>
  );
};

export default LiveJobs;
