import React from 'react';
import { useCreateUser } from '../../hooks/users';
import { useHistory } from 'react-router-dom';
import Nav from '../custom/Nav';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const validationSchema = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  // password: yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),
});

const WorkersAddForm = () => {
  const createUser = useCreateUser();
  const history = useHistory();

  const handleSubmit = async (values) => {
    values.password = 'pedeaju123';
    await createUser.mutateAsync(values);
    history.push('/meeskond');
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className='adddayform'>
      <Nav title='Lisa uus töötaja' action='back' subAction={false} />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id='firstName'
          name='firstName'
          label='Eesnimi'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          // error={formik.touched.desc && Boolean(formik.errors.desc)}
          // helperText={formik.touched.desc && formik.errors.desc}
        />
        <TextField
          fullWidth
          id='lastName'
          name='lastName'
          label='Perenimi'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          // error={formik.touched.desc && Boolean(formik.errors.desc)}
          // helperText={formik.touched.desc && formik.errors.desc}
        />
        <TextField
          fullWidth
          id='email'
          name='email'
          label='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          // error={formik.touched.desc && Boolean(formik.errors.desc)}
          // helperText={formik.touched.desc && formik.errors.desc}
        />

        <Button color='secondary' variant='contained' fullWidth type='submit'>
          Lisa
        </Button>
      </form>
    </div>
  );
};

export default WorkersAddForm;
